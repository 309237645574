




























































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { getTime } from '@/filter/dateFormat';
import { FETCH_LIST, INIT_CREATE, SET_LIST_PAGE } from '../store';
import { PaginatableMixin } from '@/views/mixin';
import AppointmentTable from '../components/AppointmentTable.vue';
import AppointmentCards from '../components/AppointmentCards.vue';
import ListSearchDialog from '@/components/ListSearchDialog.vue';
import { Appointment, AppointmentList, AppointmentView, InitCreateParam } from '@/modules/appointment/types';
import { ApiResponse, Pagination } from '@/components/types';
import CreateAppointmentDialog from '../components/CreateAppointmentDialog.vue';
import EditAppointmentDialog from '../components/EditAppointmentDialog.vue';
import CancelAppointmentDialog from '@/modules/appointment/components/CancelAppointmentDialog.vue';
import { MapStateToView } from '@/modules/appointment/model';
import CardHeader from '@/components/CardHeader.vue';
import { routeToLocation } from '@/router';

const Appointment = namespace('appointment');

@Component({
  components: {
    CardHeader,
    CancelAppointmentDialog,
    EditAppointmentDialog,
    CreateAppointmentDialog,
    ListSearchDialog,
    AppointmentCards,
    AppointmentTable
  }
})
export default class List extends PaginatableMixin {
  @Appointment.State('list') list!: AppointmentList;
  @Appointment.Action(FETCH_LIST) fetch!: () => Promise<ApiResponse<Appointment[]>>;
  @Appointment.Mutation(SET_LIST_PAGE) setPage!: (page: number) => void;
  @Appointment.Mutation(INIT_CREATE) initCreate!: (params: InitCreateParam) => void;

  loading: boolean = false;
  error: any = null;

  pagination: Pagination = {
    sortBy: ['start'],
    itemsPerPage: 10,
    page: 1,
    descending: false,
    pageCount: 0
  };

  get editAppointment(): string {
    return this.$route.query['edit'] as string;
  }

  set editAppointment(edit: string) {
    if (!edit) {
      const { edit, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, edit } })).catch(() => {});
  }

  get cancelAppointment(): string {
    return this.$route.query['cancel'] as string;
  }

  set cancelAppointment(cancel: string) {
    if (!cancel) {
      const { cancel, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, cancel } })).catch(() => {});
  }

  get items(): AppointmentView[] {
    return this.list.items
      .filter((appointment) => {
        return getTime(appointment.end) > Date.now();
      })
      .map((appointment) => MapStateToView(appointment));
  }

  async created() {
    this.loading = true;

    const { error } = await this.fetch();

    this.loading = false;

    if (error) {
      this.error = error;
    }
  }
}

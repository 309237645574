














































































import { Component, Prop } from 'vue-property-decorator';
import { Pagination } from '@/components/types';
import { AppointmentView } from '@/modules/appointment/types';
import { AuthorizationMixin } from '@/views/mixin';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: { AppTable }
})
export default class AppointmentTable extends AuthorizationMixin {
  @Prop({ default: () => [] }) items!: AppointmentView[];
  @Prop({ default: () => [] }) paginationChange!: any;
  @Prop() pagination!: Pagination;
  @Prop() search?: string;
  @Prop() loading!: boolean;

  get paginationProxy(): Pagination {
    return this.pagination;
  }

  set paginationProxy(pagination: Pagination) {
    this.$emit('update:pagination', pagination);
  }

  get headers() {
    return [
      { align: 'left', text: '', value: null, sortable: false },
      { align: 'left', text: this.$i18n.t('common.appointment'), value: 'begin' },
      { align: 'left', text: this.$i18n.t('common.title'), value: 'title' },
      { align: 'left', text: this.$i18n.t('common.conservatee'), value: 'conservatee' },
      { align: 'left', text: this.$i18n.t('common.car'), value: 'car.name' },
      ...(this.isAdmin() && this.$vuetify.breakpoint.mdAndUp ? [
        { align: 'left', text: this.$i18n.t('common.name'), value: 'employee.name' }
      ] : []),
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false }
    ];
  }
}

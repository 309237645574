



























































































import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';
import CardRow from '@/components/CardRow.vue';
import AppIterator from '@/components/AppIterator.vue';
import { AppointmentView } from '@/modules/appointment/types';
import { Pagination } from '@/components/types';

@Component({
  components: { CardRow, AppIterator }
})
export default class AppointmentTable extends AuthorizationMixin {
  @Prop({ default: () => [] }) items!: AppointmentView[];
  @Prop() pagination!: Pagination;
  @Prop() search?: string;
  @Prop() loading!: boolean;

  get paginationProxy(): Pagination {
    return this.pagination;
  }

  set paginationProxy(pagination: Pagination) {
    this.$emit('update:pagination', pagination);
  }

  appointment(appointment: AppointmentView): string {
    if (appointment.endDate !== appointment.beginDate) {
      return `${appointment.beginDate} - ${appointment.endDate}`;
    }

    return appointment.beginDate;
  }

  tooltip(appointment: AppointmentView): string {
    if (appointment.start === appointment.begin) {
      return '';
    }

    return `Abfahrt: ${appointment.startTime}`;
  }
}
